import WebServiceRequest from '../Api/WebServiceRequest'

class ConsultantRequestCreate extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('ConsultantRequest/Create')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}
class ConsultantRequestGet extends WebServiceRequest {
  constructor (context) {
    super(context)
    super.setTag('ConsultantRequest/Get')
  }
  setParams(data){
    super.setRequestParam(data)
  }
}
export {
  ConsultantRequestCreate,
  ConsultantRequestGet
};
