<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="exams">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="1"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <span> تعداد کل : </span>
              <span class="mx-1">{{ totalCount }}</span>
            </b-col>

            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector mx-50 w-50"
              />
              <label>تعداد</label>
            </b-col>

            <!-- Search -->
            <!--            <b-col-->
            <!--                cols="12"-->
            <!--                md="6"-->
            <!--            >-->
            <!--              <div class="d-flex align-items-center justify-content-end">-->
            <!--                <b-form-input-->
            <!--                    v-model="searchCommand"-->
            <!--                    class="d-inline-block mr-1"-->
            <!--                    placeholder="جستجوی شماره موبایل ..."-->
            <!--                />-->
            <!--              </div>-->
            <!--            </b-col>-->

            <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start ml-auto"
            >
              <b-button
                  variant="success"
                  class=""
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="downloadFile"
              >
                دانلود اکسل لیست
              </b-button>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="exams"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >
          <!-- Column: imageUrl -->
          <template #cell(imageUrl)="data">
            <template>
              <b-avatar
                  ref="previewEl"
                  :src="baseUrl+data.item.imageUrl"
                  size="100px"
                  rounded
              />
            </template>
          </template>

          <!-- Column: createDate -->
          <template #cell(createDate)="data">
            <span>
              {{ data.item.createDate.slice(11, 16) }}
              -
              {{ createJalaliDate(data.item.createDate.slice(0, 10)) }}
            </span>
          </template>

          <!-- Column: available -->
          <template #cell(available)="data">
            <span v-if="data.item.available" class="text-success"> موجود </span>
            <span v-else class="text-danger"> ناموجود </span>
          </template>

          <!-- Column: Edit -->
          <template #cell(edit)="data">
            <b-link :to="{ name: 'apps-exam-edit', params: { id: data.item.id } }" class="cursor-pointer">
              <feather-icon size="20" icon="EditIcon"/>
            </b-link>
          </template>

          <!-- Column: price -->
          <template #cell(price)="data">
            <span class="">
              {{ makePrettyPrice(data.item.price) }} تومان
            </span>
          </template>

          <!-- Column: show -->
          <template #cell(show)="data">
            <b-link :to="{ name: 'apps-exam-members-list', params: { id: data.item.id } }" class="cursor-pointer text-warning">
              <feather-icon size="20" icon="UsersIcon"/>
            </b-link>
          </template>

          <!-- Column: delete -->
          <template #cell(delete)="data">
            <span v-b-modal.modal-delete @click="showModal(data.item.id)" class="cursor-pointer">
              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
            </span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteExam()"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponGetAllRequest} from "@/libs/Api/Coupon";
import {CouponDeleteRequest} from "@/libs/Api/Coupon";
import Helper from "@/libs/Helper";
import {NumberGetAllRequest} from "@/libs/Api/Number";
import {ConsultantRequestGet} from "@/libs/Api/ConsultantRequest";
import {ExamDeleteRequest, ExamGetExamsRequest} from "@/libs/Api/Exam";
import {getUserData} from "@/auth/utils";
import {saveAs} from 'file-saver';
import axiosIns from "@/libs/axios";

export default {
  name: "ExamList",
  title: "لیست آزمون‌ها - پنل ادمین مکس",
  data() {
    return {
      exams: null,
      showOverlay: false,
      totalCount: null,
      baseUrl: Helper.baseUrl,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      pageNumber: 1,
      count: 10,
      searchCommand: '',
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'imageUrl', label: 'تصویر'},
        {key: 'title', label: 'عنوان'},
        {key: 'categoryName', label: 'دسته بندی'},
        {key: 'price', label: 'قیمت'},
        {key: 'available', label: 'وضعیت'},
        {key: 'totalQuestionsCount', label: 'تعداد کل پرسش ها'},
        {key: 'createDate', label: 'تاریخ ثبت'},
        {key: 'count', label: 'تعداد شرکت کنندگان'},
        {key: 'show', label: 'نمایش شرکت کنندگان'},
        {key: 'edit', label: 'ویرایش'},
        {key: 'delete', label: 'حذف'},
      ],
      defaultCreateData: {
        isDeleted: false,
        productCategoryId: 0,
        parentId: 0,
        name: ''
      },
    }
  },
  async created() {
    await this.getAllExams(this.pageNumber, this.count)
  },
  methods: {
    async getAllExams(pageNumber, count) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        page: pageNumber,
        size: count,
      }

      let examGetExamsRequest = new ExamGetExamsRequest(_this);
      examGetExamsRequest.setParams(data);
      await examGetExamsRequest.fetch(function (content) {
        _this.exams = content.data.data;
        _this.totalCount = content.data.totalCount;
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async deleteExam() {
      let _this = this;
      _this.showOverlay = true;
      let data = {
        id: _this.deleteItem
      }

      let examDeleteRequest = new ExamDeleteRequest(_this);
      examDeleteRequest.setParams(data);
      await examDeleteRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: ` حذف شد.`,
          },
        })
        _this.getAllExams(_this.pageNumber, _this.count)
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async downloadFile() {
      let _this = this;
      _this.showOverlay = true;
      let userToken = getUserData().data.token;

      await axiosIns.post(`Exam/GetExcel`, null, {
        params:{
          productId:this.productId,
          state:this.perState
        },
        responseType: 'blob',
        'Authorization': userToken,
      }).then(resp => {
        _this.showOverlay = false;
        saveAs(resp.data, 'لیست آزمون‌ها.xlsx');
      })
          .catch((error) => {
            _this.showOverlay = false;
            console.log(error);
          });
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getAllExams(nv, this.count);
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>