import WebServiceRequest from '../Api/WebServiceRequest'

class NumberAddNumberRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Number/AddNumber')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class NumberGetAllRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Number/GetAll')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

export {
    NumberAddNumberRequest,
    NumberGetAllRequest
};
